import React from 'react';
import { Link, graphql } from 'gatsby';
import gifteeLogoSVG from '../images/engineerblog_logo.svg';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const POST_WIDTH = 300;
const POST_HEIGHT = 300;

const styles = theme =>
  createStyles({
    titleContainer: {
      borderBottom: `solid 1px ${grey[300]}`,
      margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px 0`
    },
    title: {
      borderBottom: `solid 1px ${grey[500]}`,
      paddingBottom: 2.5
    },
    card: {
      height: POST_HEIGHT,
      width: POST_WIDTH,
      cursor: 'pointer',
      margin: `${theme.spacing(2)}px 0`
    },
    postImage: {
      height: '70%',
      width: '100%',
      objectFit: 'contain'
    },
    content: {
      position: 'relative',
      height: '30%',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 0
    },
    date: {
      position: 'absolute',
      bottom: theme.spacing()
    }
  });

const BlogIndex = ({ classes, data, location }) => {
  const posts = data.allMarkdownRemark.edges;

  const getBlogPostFirstImage = node => {
    if (typeof window === 'undefined') return null;
    const doc = new DOMParser().parseFromString(node.html, 'text/html');
    return doc.images.length !== 0 ? doc.images[0].src : gifteeLogoSVG;
  };

  return (
    <Layout location={location}>
      <SEO />
      <Typography color="textPrimary" variant="h5" className={classes.titleContainer}>
        <span className={classes.title}>Tech Blog</span>
      </Typography>
      <Grid container alignItems="center">
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug;
          const src = getBlogPostFirstImage(node);
          return src === null ? null : (
            <Grid item xs={12} sm={6} md={4} key={node.fields.slug} container justify="center">
              <Link to={node.fields.slug}>
                <Card className={classes.card}>
                  <img src={src} className={classes.postImage} />
                  <CardContent className={classes.content}>
                    <Typography className={classes.postTitle} color="textPrimary">
                      {title}
                    </Typography>
                    <Typography color="textSecondary" variant="body2" className={classes.date}>
                      {node.frontmatter.date}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          );
        })}
      </Grid>
    </Layout>
  );
};

export default withStyles(styles)(BlogIndex);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            description
          }
        }
      }
    }
  }
`;
